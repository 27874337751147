<template>
  <b-card
    title="Edit User"
  >
    <loading
      :active="isLoading"
      loader="bars"
      color="#7367f0"
      :is-full-page="fullPage"
    />
    <!-- form -->
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row>
          <b-col cols="12">
            <b-form-group
              label="Name"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="name"
                plaintext
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Email"
              label-for="email"
            >
              <b-form-input
                id="email"
                v-model="email"
                plaintext
              />
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Role"
              label-for="role"
            >
              <validation-provider
                #default="{ errors }"
                name="Role"
                rules="required"
              >
                <v-select
                  v-model="roles"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="roleOptions"
                  @input="onRoleChange"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="showMinistries"
            cols="12"
          >
            <b-form-group
              label="Ministries"
              label-for="ministries"
            >
              <validation-provider
                #default="{ errors }"
                name="Ministries"
                rules="required"
              >
                <v-select
                  v-model="departments"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  label="name"
                  :options="departmentOptions"
                  @option:selected="onMinistryChange"
                  @option:deselected="onMinistryDeselectedChange"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-if="showMinistries"
            cols="12"
          >
            <b-form-group
              label="Divisions"
              label-for="divisions"
            >
              <validation-provider
                #default="{ errors }"
                name="Divisions"
                rules="required"
              >
                <v-select
                  v-model="divisions"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  label="name"
                  :options="divisionOptions"
                  @option:selected="onDivisionChange"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="12">
            <b-form-group
              label="Churches"
              label-for="churches"
            >
              <validation-provider
                #default="{ errors }"
                name="Churches"
                rules="required"
              >
                <v-select
                  v-model="churches"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  multiple
                  label="name"
                  :options="churchOptions"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-button
              variant="primary"
              class="mr-1"
              type="submit"
              @click.prevent="validationForm"
            >
              Submit
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              :to="{ name: 'users' }"
            >
              Cancel
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
// import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import _ from 'lodash'
import CryptoJS from 'crypto-js'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'
import { isUserLoggedIn } from '@/auth/utils'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCard,

    vSelect,
    Loading,
  },
  directives: {
      Ripple,
  },
  data() {
    return {
      isLoading: true,
      fullPage: false,
      name: '',
      email: '',
      roles: {},
      roleOptions: [],
      departments: [],
      departmentOptions: [{
        id: 0,
        name: '--- All Ministries ---',
      }],
      divisions: [],
      divisionOptions: [{
        id: 0,
        name: '--- All Divisions ---',
      }],
      divisionLists: [],
      churches: [],
      churchOptions: [],
      userRole: '',
      showMinistries: true,
      required,
    }
  },
  async mounted() {
    // Set data
    const userData = JSON.parse(CryptoJS.AES.decrypt(sessionStorage.getItem('userData'), process.env.VUE_APP_COOKIE_SECRET).toString(CryptoJS.enc.Utf8))
    this.userRole = userData[0].role

    // Get the user data
    this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/user/${this.$route.params.id}`)
      .then(async users => {
        // Hide loading
        this.isLoading = false

        this.name = users.data[0].name
        this.email = users.data[0].email
        this.roles = { id: users.data[0].role_id, name: users.data[0].role }

        if (this.roles.id === 232 || this.roles.id === 233) {
          this.showMinistries = false
        } else {
          this.showMinistries = true
        }

        // Departments
        if (users.data[0].departments && !users.data[0].divisions) {
          const departmentIds = users.data[0].department_id.split(',')
          const departments = users.data[0].departments.split(';')

          // Loop ministries
          departments.forEach((department, index) => {
            this.departments.push({ id: departmentIds[index], name: department })
          })

          // Set division according to department
          const divisionMinistry = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/divisions/ministry/${departmentIds}`)
          divisionMinistry.data.forEach(division => {
            this.divisionOptions.push({ id: division.id, name: `${division.ministries.name} - ${division.name}` })
          })
        } else if (users.data[0].departments && users.data[0].divisions) {
          const departmentIds = users.data[0].department_id.split(',')
          const departments = users.data[0].departments.split(';')
          const divisionIds = users.data[0].division_id.split(',')
          const divisions = users.data[0].divisions.split(';')

          // Loop ministries
          departments.forEach((department, index) => {
            this.departments.push({ id: departmentIds[index], name: department })
          })

          divisions.forEach((division, index) => {
            this.divisions.push({ id: divisionIds[index], name: division })
          })

          // Set division according to department
          const divisionMinistry = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/divisions/ministry/${departmentIds}`)
          divisionMinistry.data.forEach(division => {
            this.divisionOptions.push({ id: division.id, name: `${division.ministries.name} - ${division.name}` })
          })
        } else {
          // Get all divisions
          this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/divisions?filter={"include":[{"relation":"ministries","scope":{"order":["name asc"]}},{"relation": "ministryType"}]}`)
            .then(divisions => {
              // Hide loading
              this.isLoading = false

              divisions.data.forEach(division => {
                this.divisionOptions.push({ id: division.id, name: `${division.ministries.name} - ${division.name}` })
              })
            })
        }
        
        // CHurches
        if (users.data[0].churches) {
          const churches = users.data[0].churches.split(',')

          churches.forEach(church => {
            this.churches.push({ id: church.split('-')[0], name: church.split('-')[1] })
          })
        }
      })

    // Get the roles data
    const roles = await this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/roles`)
    const allRoles = []
    
    // Filter based on role
    if (userData[0].role === 'PIC Servolution Regional') {
      roles.data = _.filter(roles.data, item => item.name === 'PIC Servolution Regional' || item.name === 'PIC Servolution Local' || item.name === 'PIC Ministry' || item.name === 'PIC Baptism' || item.name === 'PIC Servolution Assessment')
    } else if (userData[0].role === 'PIC Servolution Local') {
      roles.data = _.filter(roles.data, item => item.name === 'PIC Servolution Local' || item.name === 'PIC Ministry' || item.name === 'PIC Baptism' || item.name === 'PIC Servolution Assessment')
    } else if (userData[0].role === 'PIC Ministry') {
      roles.data = _.filter(roles.data, item => item.name === 'PIC Servolution Assessment')
    }
    roles.data.forEach(role => {
        const roleData = {
            id: role.id,
            name: role.name,
        }

        allRoles.push(roleData)
    })
    this.roleOptions = allRoles

    // Get all departments
    this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/ministries?[where][deletedAt]=null`)
      .then(ministries => {
        // Hide loading
        this.isLoading = false

        ministries.data.forEach(ministry => {
          this.departmentOptions.push({ id: ministry.id, name: ministry.name })
        })
      })

    // Get the church list
    this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/churches`)
      .then(churches => {
        // Hide loading
        this.isLoading = false
        
        churches.data.forEach(church => {
          this.churchOptions.push({ id: church.couchdbId, name: church.name })
        })
      })
  },
  methods: {
    onRoleChange(role) {
      if (role.name === 'PIC Servolution Regional' || role.name === 'PIC Servolution Local') {
        // Disable the view
        this.showMinistries = false
      } else {
        this.showMinistries = true
      }
    },
    onMinistryChange(ministry) {
      // Show loading
      this.isLoading = true

      // Check for default -- all ministry -- option
      const allMinistry = _.filter(ministry, o => o.id === 0)
      // If exist
      if (!_.isEmpty(allMinistry)) {
        this.departments = []
        this.divisions = []

        this.departments.push({ id: 0, name: '--- All Ministries ---' })
        this.divisions.push({ id: 0, name: '--- All Divisions ---' })

        // Get division
        this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/divisions?filter={"include":[{"relation":"ministries","scope":{"order":["name asc"]}},{"relation": "ministryType"}]}`)
          .then(divisions => {
            this.divisionOptions = [{
              id: 0,
              name: '--- All Divisions ---',
            }]
            _.forEach(divisions.data, result => {
                this.divisionOptions.push({ id: result.id, name: `${result.ministries.name} - ${result.name}` })
            })

            // Hide loading
            this.isLoading = false
          })
      } else {
        const ministries = ministry.map(elem => (`${elem.id}`)).join(',')
      
        // Get division
        this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/divisions/ministry/${ministries}`)
          .then(divisions => {
            this.divisionOptions = [{
              id: 0,
              name: '--- All Divisions ---',
            }]
            _.forEach(divisions.data, result => {
                this.divisionOptions.push({ id: result.id, name: `${result.ministries.name} - ${result.name}` })
            })

            // Hide loading
            this.isLoading = false
          })
      }
    },
    onMinistryDeselectedChange(ministry) {
      // Show loading
      this.isLoading = true
      
      if (ministry.id === 0 || _.isEmpty(this.departments)) {
        // Hide loading
        this.isLoading = false

        this.divisions = []
        this.divisionOptions = []
      } else {
        // Get division
        this.$http.get(`${process.env.VUE_APP_API_BASE_URL}/api/divisions/ministry/${ministry.id}`)
          .then(divisions => {
            _.forEach(divisions.data, division => {
              const removedDivisionIndex = _.findIndex(this.divisions, { id: division.id })
              const removedDivisionOptionIndex = _.findIndex(this.divisionOptions, { id: division.id })

              if (removedDivisionIndex > -1) {
                this.divisions.splice(removedDivisionIndex, 1)
              }

              if (removedDivisionOptionIndex > -1) {
                this.divisionOptions.splice(removedDivisionOptionIndex, 1)
              }

              if (_.isEmpty(this.divisions)) {
                this.divisionOptions = []
              }

              // Hide loading
              this.isLoading = false
            })
          })
      }
    },
    onDivisionChange(division) {
      // Show Loading
      this.isLoading = true

      const allDivision = _.filter(division, o => o.id === 0)
      if (!_.isEmpty(allDivision)) {
        this.divisions = []
        this.divisions.push({ id: 0, name: '--- All Divisions ---' })
      }

      // Hide Loading
      this.isLoading = false
    },
    validationForm() {
      // Check for cookies before submit
      // Get logged in status
      const isLoggedIn = isUserLoggedIn()
      // If not logged in, then redirect to login page
      if (!isLoggedIn) {
        return this.$router.replace('/login')
      }
      
      // Submit
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          // Show loading
          this.isLoading = true

          // Check if user has access
          if (!this.$can('update', 'user')) {
            this.isLoading = false
            return this.$router.replace({ name: 'misc-not-authorized' })
          }

          // Check role first (PIC Servolution Regional / Local)
          let ministries = []
          let divisions = []
          if (this.roles.id === 232 || this.roles.id === 233 || (this.departments[0].id === 0 && this.divisions[0].id === 0)) {
            ministries = `{ ${_.filter(this.departmentOptions, o => o.id !== 0).map(elem => (`${elem.id}`)).join(',')} }`
            divisions = `{ ${_.filter(this.divisionOptions, o => o.id !== 0).map(elem => (`${elem.id}`)).join(',')} }`
          } else if (this.departments[0].id === 0 && this.divisions[0].id !== 0) {
            ministries = `{ ${_.filter(this.departmentOptions, o => o.id !== 0).map(elem => (`${elem.id}`)).join(',')} }`
            divisions = `{ ${this.divisions.map(elem => (`${elem.id}`)).join(',')} }`
          } else if (this.departments[0].id !== 0 && this.divisions[0].id === 0) {
            ministries = `{ ${this.departments.map(elem => (`${elem.id}`)).join(',')} }`
            divisions = `{ ${_.filter(this.divisionOptions, o => o.id !== 0).map(elem => (`${elem.id}`)).join(',')} }`
          } else {
            ministries = `{ ${this.departments.map(elem => (`${elem.id}`)).join(',')} }`
            divisions = `{ ${this.divisions.map(elem => (`${elem.id}`)).join(',')} }`
          }

          // Update user data first
          this.$http.patch(`${process.env.VUE_APP_API_BASE_URL}/api/user/${this.$route.params.id}`, {
            churches: `{ ${this.churches.map(elem => (`${elem.id}`)).join(',')} }`,
            departments: ministries,
            divisions,
            updatedAt: new Date(),
          }).then(async () => {
            // set user role data
            const userRoleData = {
              rolesId: this.roles.id,
              updatedAt: new Date(),
            }
            
            // Update user role
            this.$http.patch(`${process.env.VUE_APP_API_BASE_URL}/api/user/role?[where][usersId]=${this.$route.params.id}`, userRoleData).then(() => {
              // Redirect to status lists
              this.isLoading = false
              this.$router.replace({ name: 'users' })
                .then(() => {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Success',
                      icon: 'CheckIcon',
                      variant: 'success',
                      text: 'Data has been updated successfully.',
                    },
                  })
                })
              })
            })
        }
      })
    },
  },
}
</script>